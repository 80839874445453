import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = {
  key: 0,
  class: "col"
}
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { class: "row" }
const _hoisted_9 = { class: "row" }
const _hoisted_10 = { class: "row" }
const _hoisted_11 = {
  key: 1,
  class: "col"
}
const _hoisted_12 = { key: 0 }
const _hoisted_13 = { key: 1 }
const _hoisted_14 = { class: "row" }
const _hoisted_15 = { style: {"background-color":"rgba(0, 0, 0, 0.1)"} }
const _hoisted_16 = {
  key: 0,
  class: "col"
}
const _hoisted_17 = { class: "row" }
const _hoisted_18 = { class: "row" }
const _hoisted_19 = { class: "row" }
const _hoisted_20 = {
  key: 1,
  class: "col"
}
const _hoisted_21 = { class: "row" }
const _hoisted_22 = { style: {"background-color":"rgba(0, 0, 0, 0.1)"} }

import { computed } from 'vue';
import { DatamatchingGroupedResultDTO } from 'greenzonegateway.classes/lib/classes';
import GZIndicator from '@/components/GZIndicator.vue';

interface DatamatchingGroupedResultDTOInternal
	extends DatamatchingGroupedResultDTO {
	InternalSiteRowId: number | null | undefined;
	expand: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'DetailsSiteRow',
  props: {
    settings: {},
    propsIn: {}
  },
  emits: [
	'showModalScheduleMapping',
	'showModalBinMappings',
	'showModalSiteMappings',
	'openSidePanel',
	'clearSelections',
	'update:propsIn',
	'expand',
],
  setup(__props: any, { emit: __emit }) {

const props = computed({
	get() {
		return _props.propsIn;
	},
	set(value: any) {
		_emit('update:propsIn', value);
	},
});

const _props = __props;
const _emit = __emit;

function GetSiteRowColor(hasProblems: boolean, IsSiteCommitted: boolean) {
	let colorOut = '';
	IsSiteCommitted ? (colorOut = 'var(--q-positive)') : null;
	!IsSiteCommitted && hasProblems ? (colorOut = 'var(--q-negative)') : null;
	return colorOut;
}

function CheckBinsSaved(rowData: DatamatchingGroupedResultDTOInternal) {
	if (!rowData.BinResults) {
		return;
	}
	let savedBinsCount = 0;
	rowData.BinResults.forEach((bin) => {
		bin.WeightEntered?.Display ? savedBinsCount++ : null;
	});

	return savedBinsCount ? true : false;
}

function ShowSiteMappingsModal(
	rowData: DatamatchingGroupedResultDTOInternal,
	index: number
) {
	_emit('showModalSiteMappings', { rowData, index });
}

return (_ctx: any,_cache: any) => {
  const _component_q_tooltip = _resolveComponent("q-tooltip")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_tr = _resolveComponent("q-tr")!

  return (_openBlock(), _createBlock(_component_q_tr, {
    id: `row-${[props.value.row.InternalSiteRowId]}`,
    style: {"background-color":"rgba(255, 177, 0, 0.05)"},
    onClick: _cache[2] || (_cache[2] = ($event: any) => {
			{
				_emit('expand');
				props.value.row.expand = !props.value.row.expand;
			}
		})
  }, {
    default: _withCtx(() => [
      _createVNode(_component_q_td, { style: {"width":"708px"} }, {
        default: _withCtx(() => [
          (GetSiteRowColor(props.value.row.HasProblems, props.value.row.IsSiteCommitted))
            ? (_openBlock(), _createElementBlock("div", _hoisted_1))
            : _createCommentVNode("", true),
          (GetSiteRowColor(props.value.row.HasProblems, props.value.row.IsSiteCommitted))
            ? (_openBlock(), _createBlock(GZIndicator, {
                key: 1,
                color: 
					GetSiteRowColor(props.value.row.HasProblems, props.value.row.IsSiteCommitted)
				
              }, {
                default: _withCtx(() => [
                  (!props.value.row.IsSiteCommitted || props.value.row.HasProblems)
                    ? (_openBlock(), _createBlock(_component_q_tooltip, {
                        key: 0,
                        anchor: "top middle",
                        self: "bottom middle",
                        offset: [10, 10]
                      }, {
                        default: _withCtx(() => [
                          (!props.value.row.IsSiteCommitted)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_2, " One or more bins aren't saved "))
                            : _createCommentVNode("", true),
                          (props.value.row.HasProblems)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_3, "One or more bins have problems"))
                            : _createCommentVNode("", true)
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["color"]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_4, [
            (!_ctx.settings.slimGroups)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  (!props.value.row.IsSiteCommitted || props.value.row.HasProblems)
                    ? (_openBlock(), _createBlock(_component_q_tooltip, {
                        key: 0,
                        anchor: "top middle",
                        self: "bottom middle",
                        offset: [10, 10]
                      }, {
                        default: _withCtx(() => [
                          (!props.value.row.IsSiteCommitted)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_6, " One or more bins aren't saved "))
                            : _createCommentVNode("", true),
                          (props.value.row.HasProblems)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_7, " One or more bins have problems "))
                            : _createCommentVNode("", true)
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("b", null, _toDisplayString(props.value.row.SupplierSiteName), 1)
                  ]),
                  _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("i", null, _toDisplayString(props.value.row.SupplierSiteAddress), 1)
                  ]),
                  _createElementVNode("div", _hoisted_10, [
                    _createElementVNode("i", null, _toDisplayString(props.value.row.SupplierSitePostcode), 1)
                  ])
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_11, [
                  (!props.value.row.IsSiteCommitted || props.value.row.HasProblems)
                    ? (_openBlock(), _createBlock(_component_q_tooltip, {
                        key: 0,
                        anchor: "top middle",
                        self: "bottom middle",
                        offset: [10, 10]
                      }, {
                        default: _withCtx(() => [
                          (!props.value.row.IsSiteCommitted)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_12, " One or more bins aren't saved "))
                            : _createCommentVNode("", true),
                          (props.value.row.HasProblems)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_13, " One or more bins have problems "))
                            : _createCommentVNode("", true)
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", _hoisted_14, [
                    _createElementVNode("b", null, _toDisplayString(props.value.row.SupplierSiteName) + " ", 1),
                    _createElementVNode("div", _hoisted_15, _toDisplayString(props.value.row.SupplierSiteAddress), 1),
                    _createElementVNode("div", null, " " + _toDisplayString(props.value.row.SupplierSitePostcode), 1)
                  ])
                ]))
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_q_td, { style: {"width":"112px"} }, {
        default: _withCtx(() => [
          _createVNode(_component_q_btn, {
            icon: "mdi-map-outline",
            size: _ctx.settings.slimGroups ? `sm` : `md`,
            round: "",
            flat: "",
            disable: CheckBinsSaved(props.value.row),
            onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (ShowSiteMappingsModal(props.value.row, props.value.rowIndex)), ["stop"]))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_q_tooltip, {
                class: "bg-accent",
                anchor: "top middle"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", null, "Site Mapping" + _toDisplayString(CheckBinsSaved(props.value.row)
								? ` - DISABLED: One of the bins is saved`
								: ``), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["size", "disable"])
        ]),
        _: 1
      }),
      _createVNode(_component_q_td, null, {
        default: _withCtx(() => [
          (!_ctx.settings.slimGroups)
            ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                _createElementVNode("div", _hoisted_17, [
                  _createElementVNode("b", null, _toDisplayString(props.value.row.MatchedSiteName), 1)
                ]),
                _createElementVNode("div", _hoisted_18, [
                  _createElementVNode("i", null, _toDisplayString(props.value.row.MatchedSiteAddress), 1)
                ]),
                _createElementVNode("div", _hoisted_19, [
                  _createElementVNode("i", null, _toDisplayString(props.value.row.MatchedSitePostcode), 1)
                ])
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_20, [
                _createElementVNode("div", _hoisted_21, [
                  _createElementVNode("b", null, _toDisplayString(props.value.row.MatchedSiteName) + " ", 1),
                  _createElementVNode("div", _hoisted_22, _toDisplayString(props.value.row.MatchedSiteAddress), 1),
                  _createElementVNode("div", null, " " + _toDisplayString(props.value.row.MatchedSitePostcode), 1)
                ])
              ]))
        ]),
        _: 1
      }),
      _createVNode(_component_q_td, { style: {"width":"86px"} }, {
        default: _withCtx(() => [
          _createVNode(_component_q_btn, {
            size: "sm",
            color: "accent",
            round: "",
            dense: "",
            onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => {
					{
						_emit('expand');
						props.value.row.expand = !props.value.row.expand;
					}
				}, ["stop"])),
            icon: props.value.row.expand ? 'mdi-chevron-up' : 'mdi-chevron-down'
          }, null, 8, ["icon"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["id"]))
}
}

})