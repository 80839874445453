/* eslint-disable max-len */
import Vue from 'vue';
import singleSpaVue from 'single-spa-vue';
import customerServiceHub from './plugins/customerServiceHub';
import { PiniaVuePlugin, createPinia } from 'pinia';

import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import VueAppInsights from './plugins/ms-app-insights';
import { ClickAnalyticsPlugin } from '@microsoft/applicationinsights-clickanalytics-js';
import VueVirtualScroller from 'vue-virtual-scroller';
import VueEditor from 'vue2-editor';

import { isLoggedIn$, user$ } from '@gz/auth';

import VueCompositionAPI from '@vue/composition-api';
Vue.use(VueCompositionAPI);

Vue.config.productionTip = false;
// Vue.config.devtools = true;
Vue.use(PiniaVuePlugin);

const pinia = createPinia();
Vue.use(VueVirtualScroller);

// make sure to use this when creating new project
// single-spa doesn't work with vuetify on CLI
// So comment out single-spa stuff and readd the below
// then amend the single spa after plugin has worked

// new Vue({
// 	router,
// 	store,
// 	vuetify,
// 	render: h => h(App),
// }).$mount('#app');

// Base css4 Variable styles
import './styles/base-styles.scss';
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';

export const nameof = <T>(name: Extract<keyof T, string>): string => name;

(String.prototype as any).toProperCase = function () {
	return this.replace(/\w\S*/g, function (txt: any) {
		return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
	});
};

const vueLifecycles = singleSpaVue({
	Vue,
	appOptions: {
		render(h: any) {
			return h(App, {
				props: {
					// single-spa props are available on the "this" object. Forward them to your component as needed.
					// https://single-spa.js.org/docs/building-applications#lifecyle-props
					// if you uncomment these, remember to add matching prop definitions for them in your App.vue file.
					/*
          name: this.name,
          mountParcel: this.mountParcel,
          singleSpa: this.singleSpa,
          */
				},
			});
		},
		router,
		vuetify,
		pinia: pinia,
	},
});

const clickPluginInstance = new ClickAnalyticsPlugin();
// Click Analytics configuration
const clickPluginConfig = {
	autoCapture: true,
	enableAutoRouteTracking: true,
	autoTrackPageVisitTime: true,
	appId: 'Greenzone Gateway - Customer Support',
	dataTags: {
		useDefaultContentNameOrId: true,
		enableAutoRouteTracking: true,
	},
};

// Application Insights Configuration
const configObj = {
	connectionString: process.env.VUE_APP_MS_ConnectionString,
	autoTrackPageVisitTime: true,
	accountId: 'Dan Test Account',
	extensions: [clickPluginInstance],
	extensionConfig: {
		[clickPluginInstance.identifier]: clickPluginConfig,
	},
};

isLoggedIn$.subscribe(async (isLoggedIn: boolean) => {
	if (isLoggedIn) {
		await Vue.use(customerServiceHub);
	}
});

user$.subscribe((user: any) => {
	if (!user) {
		return;
	}

	if (configObj.connectionString) {
		Vue.use(VueAppInsights, {
			appInsightsConfig: configObj,
			loggedInUser: user.name,
			accountId: user.name,
			baseName: 'Customer Support ',
			router,
		});
	}
});

export const { bootstrap } = vueLifecycles;
export const { mount } = vueLifecycles;
export const { unmount } = vueLifecycles;
